import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

const StatRow = (props: any) => {
  console.log(props);
  return (
    <div>
      <Typography variant="h6" component="h6">
        {props.stat.displayName}
      </Typography>
      <Typography variant="subtitle1" component="div">
        {props.stat.displayValue}
      </Typography>
    </div>
  );
};
const ScoreCard = (props: any) => {
  const { player } = props;
  const { stats } = player;
  const { stats: statList } = stats;
  console.log(stats);
  return (
    <Card>
      <CardHeader title={player.username} />
      <CardContent>
        <StatRow stat={statList.timePlayed}></StatRow>
        <StatRow stat={statList.score}></StatRow>
        <StatRow stat={statList.matchesPlayed}></StatRow>
        <StatRow stat={statList.matchesWon}></StatRow>
        <StatRow stat={statList.matchesLost}></StatRow>
        <StatRow stat={statList.kills}></StatRow>
        <StatRow stat={statList.deaths}></StatRow>
        <StatRow stat={statList.kdRatio}></StatRow>
        <StatRow stat={statList.kda}></StatRow>
        <StatRow stat={statList.avgTimeAlive}></StatRow>
      </CardContent>
    </Card>
  );
};

export default ScoreCard;
