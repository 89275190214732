const config = () => {
  const dev = {
    // overviewEndpoint: "/overviews",
    overviewEndpoint: "https://6abj2mtl1k.execute-api.us-east-2.amazonaws.com/prod/overviews",
  };
  const production = {
    overviewEndpoint:
      "https://6abj2mtl1k.execute-api.us-east-2.amazonaws.com/prod/overviews",
  };
  return process.env.NODE_ENV === "production" ? production : dev;
};

export default config();
