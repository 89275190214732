import React, { useEffect, useState } from "react";
import "./App.css";
import Grid from "@mui/material/Grid";
import ScoreCard from "./components/ScoreCard/ScoreCard";
import testData from './testData';
import config from './config';

const getInfo = async () => {
  const resp = await fetch(config.overviewEndpoint);
  console.log(resp);
  return testData;
};
function App() {
  const [stats, setStats] = useState([]);
  useEffect(() => {
    console.log("useEffect");
    async function getIt() {
      try {
        const results = await getInfo();
        //@ts-ignore
        setStats(results);
        console.log(results);
      } catch (error) {
        console.log("error", error);
      }
    }
    getIt();
  }, []);

  return (
    <Grid container spacing={2}>
      {stats.map((player) => (
        <Grid item lg={3}>
          <ScoreCard player={player}/>
        </Grid>
      ))}
    </Grid>
  );
}

export default App;
